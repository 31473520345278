import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronDown, Menu, X, ExternalLink, Globe, Search, FileText } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/images/logo.png';
import dataImage from '../assets/images/datafinance.webp';
import supervesionImage from '../assets/images/supervesionimage.webp';
import interBankImg from '../assets/images/interbankimage.webp';
import bankImg from '../assets/images/Banque-centrale-de-Mauritanie.jpg';
import moneyBg from '../assets/images/money-bg.jpg';

interface MenuItem {
  id: number | string;
  name: string;
  title_fr?: string;
  title_ar?: string;
  link: string;
  dropdown?: MenuItem[];
  isExternal?: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00923f;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  animation: ${props => props.isOpen ? slideIn : slideOut} 0.3s ease-in-out;
  transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  outline: none;
  width: 200px;
  transition: all 0.3s;

  &:focus {
    width: 250px;
    border-color: #00923f;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
`;

const MobileSearchContainer = styled.div`
  flex-grow: 1;
  margin: 0 0.5rem;
  display: flex;
`;

const MobileSearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px 0 0 4px;
`;

const MobileSearchButton = styled.button`
  background-color: #00923f;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface NavigationProps {
  menuItems: MenuItem[];
}

const Navigation: React.FC<NavigationProps> = ({ menuItems }) => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language, setLanguage } = useLanguage();
  const [openMenuItem, setOpenMenuItem] = useState<MenuItem | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();

  const [navbarHeight, setNavbarHeight] = useState(0);
  const [topContainerHeight, setTopContainerHeight] = useState(0);
  const navbarRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const topContainerRef = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isHoveringMegaMenu, setIsHoveringMegaMenu] = useState(false);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchTerm('');
    }
  };

  const handleLanguageChange = () => {
    const lang = language === 'fr' ? 'ar' : 'fr';
    setLanguage(lang);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > topContainerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [topContainerHeight]);

  useEffect(() => {
    const updateHeights = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.offsetHeight);
      }
      if (topContainerRef.current) {
        setTopContainerHeight(topContainerRef.current.offsetHeight);
      }
    };
  
    updateHeights();
    window.addEventListener('resize', updateHeights);
    return () => window.removeEventListener('resize', updateHeights);
  }, []);

  const handleMouseEnter = (item: MenuItem) => {
    setOpenMenuItem(item);
  };

  const handleHeaderMouseLeave = () => {
    if (!isHoveringMegaMenu) {
      setOpenMenuItem(null);
    }
  };

  const handleMegaMenuMouseEnter = () => {
    setIsHoveringMegaMenu(true);
  };

  const handleMegaMenuMouseLeave = () => {
    setIsHoveringMegaMenu(false);
    setOpenMenuItem(null);
  };

  const staticHeaderElements = {
    Carrières: "https://carrieres.bcm.mr/fr/",
    PNRD: "https://mauritania.opendataforafrica.org/",
  }
  const staticHeaderItems: MenuItem[] = Object.entries(staticHeaderElements).map(([name, link], index) => ({
    id: `static-${index}`,
    name,
    link,
    isExternal: link.startsWith('http')
  }));

  const handleMobileMenuToggle = () => {
    if (isMenuOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsMenuOpen(false);
        setIsClosing(false);
      }, 300);
    } else {
      setIsMenuOpen(true);
    }
  };

  const closeMobileMenu = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsClosing(false);
      setOpenMenuItem(null);
    }, 300);
  };

  const DropdownLink = ({ item, language }: { item: MenuItem; language: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLLIElement>(null);
    const [dropdownPosition, setDropdownPosition] = useState<'right' | 'left'>('left');
  
    useEffect(() => {
      if (isOpen && dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const parentMiddle = rect.left + rect.width / 2;
        const screenMiddle = window.innerWidth / 2;
        setDropdownPosition(parentMiddle > screenMiddle ? 'left' : 'right');
      }
    }, [isOpen]);
  
    return (
      <li ref={dropdownRef} className="relative group" onMouseLeave={() => setIsOpen(false)}>
        {item.isExternal ? (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-between px-2 py-2 text-gray-700 hover:bg-green-100 hover:text-green-800 transition duration-300 text-xs font-bold"
          >
            {language === 'fr' ? item.title_fr : item.title_ar}
          </a>
        ) : (
          <Link
            to={item.link}
            className="flex items-center justify-between px-2 py-2 text-gray-700 hover:bg-green-100 hover:text-green-800 transition duration-300 text-xs font-bold"
            onMouseEnter={() => setIsOpen(true)}
          >
            {item.name}
            {item.dropdown && <ChevronDown className="h-4 w-4 ml-2" />}
          </Link>
        )}
        {item.dropdown && (
          <ul
            className={`absolute z-20 ${dropdownPosition === 'right' ? 'left-full' : 'right-full'
              } top-0 w-56 bg-white shadow-lg py-1 rounded-md 
              transition-all duration-300 ease-in-out 
              ${isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}
          >
            {item.dropdown.map((subItem) => (
              <DropdownLink key={subItem.id} item={subItem} language={language} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  const NavLink = ({ item, language, setOpenMenuItem, isOpen }: { item: MenuItem; language: string; setOpenMenuItem: (item: MenuItem | null) => void; isOpen: boolean }) => {
    const navigate = useNavigate();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  
    const debouncedSetOpenMenuItem = useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => setOpenMenuItem(item), 100);
    }, [item, setOpenMenuItem]);
  
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
  
    return (
      <li 
        className="relative group" 
        onMouseEnter={debouncedSetOpenMenuItem}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          // Don't set openMenuItem to null here
        }}
      >
        {item.isExternal ? (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center px-2 py-2 text-white hover:underline hover:underline-offset-1 rounded-md transition duration-300 text-xs font-bold"
          >
            {language === 'fr' ? item.title_fr : item.title_ar}
          </a>
        ) : (
          <button
            className={`flex items-center px-2 py-2 text-white hover:border-b-2 hover:border-white transition duration-300 text-xs font-extrabold ${isOpen ? 'border-b-2 border-white ]' : ''}`}
            onClick={() => navigate(item.link)}
          >
            {language === 'fr' ? item.title_fr : item.title_ar}
            {item.dropdown && <ChevronDown className={`h-4 w-4 ml-1 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />}
          </button>
        )}
      </li>
    );
  };


  const FileLink = styled.a`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const FileName = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px;
`;
  
  const MegaMenu = ({ item, setOpenMenuItem, language, navigate }) => {
    const menuRef = useRef(null);
  
    const itemImages = {
      'Présentation': bankImg,
      'Données financières et économiques': dataImage,
      'Marchés': interBankImg,
      'Supervision bancaire et financière': supervesionImage,
      'Monnaie et Réformes': moneyBg
    };
  
    useEffect(() => {
      if (!item || !item.dropdown) return;
      const updateGradient = () => {};
      updateGradient();
      window.addEventListener('resize', updateGradient);
      return () => window.removeEventListener('resize', updateGradient);
    }, [item, menuRef]);
  
    if (!item || !item.dropdown) return null;
  
    const handleClick = (link: string) => {
      setOpenMenuItem(null);
      navigate(link);
    };



    // console.log( 'item ...',item)
  
    return (
      <div
        key={item.id}
        ref={menuRef}
        className="absolute left-0 right-0 shadow-lg text-white z-50 overflow-hidden"
        style={{
          background: `
            linear-gradient(to right,
              rgba(0, 146, 63, 0.9) 0%,
              rgba(0, 146, 63, 0.9) 3%,
              rgba(0, 146, 63, 0.9) 33%,
              rgba(0, 146, 63, 0.9) 96%,
              rgba(0, 146, 63, 0.9) 100%
            ),
            url(${itemImages[item.name]}) no-repeat center
          `,
          backgroundSize: 'cover',
        }}
        onMouseEnter={handleMegaMenuMouseEnter}
        onMouseLeave={handleMegaMenuMouseLeave}
      >
        <div className="container mx-auto relative z-10">
          <div className="flex justify-center">
            <div className="w-full max-w-screen-xl px-28 py-12 flex items-start">
              <div className="grid grid-cols-3 gap-20 flex-grow">
                {item.dropdown.map((subItem, index) => (
                  <div key={subItem.id + subItem.name} className="space-y-2 flex flex-col backdrop-blur-sm bg-white/10 rounded-lg p-6 transition-all duration-300 hover:bg-white/20">
                    <h3 className="font-bold text-xl cursor-pointer pb-2 border-b border-white/30" onClick={() => handleClick(subItem.link)}>
                      {language === 'fr' ? subItem.title_fr : subItem.title_ar}
                    </h3>
                    {subItem.dropdown && (
                      <ul className="space-y-3">
                        {subItem.dropdown.map((grandChild) => (
                          <li key={grandChild.id}>
                            <span
                              onClick={() => handleClick(grandChild.link)}
                              className="text-sm text-white cursor-pointer hover:text-green-200 transition-colors duration-200 flex items-center"
                            >
                              <ChevronDown className="h-4 w-4 mr-2 transform -rotate-90" />
                              {language === 'fr' ? grandChild.title_fr : grandChild.title_ar}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}

                    {/* <div>
                      {subItem}
                    </div> */}


                      {/* Add file links */}
                      {subItem.files_fr && subItem.files_fr.data && language === 'fr' && (
                      <ul className="space-y-2 mt-4">
                        {subItem.files_fr.data.map((file) => (
                          <li key={file.id}>
                            <FileLink
                              href={`https://api.sitewebbcm.com${file.attributes.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm text-white hover:text-green-200 transition-colors duration-200"
                            >
                              <FileText className="h-4 w-4 flex-shrink-0" />
                              <FileName>{file.attributes.name}</FileName>
                              <ExternalLink className="h-3 w-3 flex-shrink-0" />
                            </FileLink>
                          </li>
                        ))}
                      </ul>
                    )}
                    {subItem.files_ar && subItem.files_ar.data && language === 'ar' && (
                      <ul className="space-y-2 mt-4">
                        {subItem.files_ar.data.map((file) => (
                          <li key={file.id}>
                            <FileLink
                              href={`https://api.sitewebbcm.com${file.attributes.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm text-white hover:text-green-200 transition-colors duration-200"
                            >
                              <FileText className="h-4 w-4 flex-shrink-0" />
                              <FileName>{file.attributes.name}</FileName>
                              <ExternalLink className="h-3 w-3 flex-shrink-0" />
                            </FileLink>
                          </li>
                        ))}
                      </ul>
                    )}

                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileDropdownLink = ({ item, language, depth = 0 }: any) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const redirect = (link: string) => {
      navigate(link);
      closeMobileMenu();
    }

    return (
      <li>
        <div className="flex items-center justify-between">
          {item.isExternal ? (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`block w-full px-4 py-2 text-sm font-bold text-white hover:bg-green-700 transition duration-300`}
              style={{ paddingLeft: `${depth * 1 + 1}rem` }}
              onClick={closeMobileMenu}
            >
              {language === 'fr' ? item.title_fr : item.title_ar}
            </a>
          ) : (
            <div
              onClick={() => redirect(item.link)}
              className={`cursor-pointer block w-full px-4 py-2 text-sm font-bold text-white hover:bg-green-700 transition duration-300`}
              style={{ paddingLeft: `${depth * 1 + 1}rem` }}
            >
              {language === 'fr' ? item.title_fr : item.title_ar}
            </div>
          )}
          {item.dropdown && (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="px-4 py-2 text-white"
            >
              <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
            </button>
          )}
        </div>
        {item.dropdown && isOpen && (
          <ul className="bg-green-600">
            {item.dropdown.map((subItem) => (
              <MobileDropdownLink key={subItem.id} item={subItem} language={language} depth={depth + 1} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  const handleMobileSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchTerm('');
      setIsMenuOpen(false); // Close the mobile menu after search
    }
  };

  return (
    <header ref={headerRef} className="relative" onMouseLeave={handleHeaderMouseLeave}>
      {/* Desktop layout */}
      <div className="hidden lg:block">
        <div ref={topContainerRef} className='bg-white flex justify-between items-center py-2 lg:px-32 px-4' onMouseEnter={() => setOpenMenuItem(null)}>
          <Link to={"/"}>
            <img src={logo} alt="" className='h-16 object-contain' />
          </Link>

          <div className='flex gap-2 items-center pl-2'>
            <SearchContainer>
              <form onSubmit={handleSearch}>
                <SearchInput
                  type="text"
                  placeholder={t('search', 'Search...')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SearchButton type="submit">
                  <Search className="h-4 w-10 text-gray-600" />
                </SearchButton>
              </form>
            </SearchContainer>

            {staticHeaderItems.map((item) => (
              <a
                key={item.id}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center gap-2 rounded-md w-full px-4 py-2 text-xs text-white font-bold bg-primary transition duration-300 ${language == 'ar' ? 'flex-row-reverse' : 'flex-row'}`}
              >
                <span>{t(item.name, item.name)}</span>
                <ExternalLink className={`h-4 w-4`} />
              </a>
            ))}

            <div className={`hidden lg:flex items-center space-x-2 border-gray-500 ${language === 'fr' ? ' border-l pl-2' : ' border-r pr-2'}`}>
              <a
                onClick={() => handleLanguageChange()}
                className={`cursor-pointer flex items-center gap-1 py-2 text-sm font-bold transition-colors text-black rounded-md`}
              >
                <Globe className={`h-4 w-4`} />
                <span>
                  {language == 'fr' ? 'ع' : t('fr', 'FR')}
                </span>
              </a>
            </div>
          </div>
        </div>

        {/* Sticky navigation bar */}
        <div
          ref={navbarRef}
          className={`transition-all duration-300 ease-in-out ${
            isSticky ? 'fixed top-0 left-0 right-0 shadow-md' : 'relative'
          } bg-nav-image z-50 lg:bg-yellowSecondary bg-primary`}
        >
          <div className="container mx-auto">
            <nav className="flex justify-center relative">
              <ul className="flex items-center justify-center max-w-screen-xl w-full px-4 py-2">
                {menuItems.map((item) => (
                  <NavLink
                    key={item.id}
                    item={item}
                    language={language}
                    setOpenMenuItem={setOpenMenuItem}
                    isOpen={openMenuItem?.id === item.id}
                  />
                ))}
              </ul>
            </nav>
          </div>
        </div>

        {/* Mega menu container */}
        <div 
          className={`transition-all duration-300 ease-in-out ${
            openMenuItem ? 'opacity-100' : 'opacity-0 pointer-events-none'
          } ${isSticky ? '' : ''}`}
          style={{ 
            position: isSticky ? 'fixed' : 'absolute', 
            top: isSticky ? `${navbarHeight}px` : '100%',
            left: 0, 
            right: 0, 
            zIndex: 40 
          }}
        >
          <MegaMenu
            item={openMenuItem}
            language={language}
            setOpenMenuItem={setOpenMenuItem}
            navigate={navigate}
          />
        </div>

        {isSticky && <div style={{ height: `${navbarHeight}px` }} />}
      </div>

      {/* Mobile layout */}
      <div className="lg:hidden">
        <MobileHeader>
          <Link to="/">
            <img src={logo} alt="Logo" className="h-8 object-contain" />
          </Link>
        
          <MobileSearchContainer>
            <form onSubmit={handleMobileSearch} className="flex w-full">
              <MobileSearchInput
                type="text"
                placeholder={t('search', 'Search...')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <MobileSearchButton type="submit">
                <Search className="h-4 w-4" />
              </MobileSearchButton>
            </form>
          </MobileSearchContainer>
          <button
            onClick={handleMobileMenuToggle}
            className="text-primary focus:outline-none"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </MobileHeader>
      </div>

      {/* Mobile menu */}
      {(isMenuOpen || isClosing) && (
        <MobileMenuContainer isOpen={isMenuOpen}>
          <div className="flex-grow px-4 py-6 overflow-y-auto">
            <div className="px-4 py-4 flex justify-end">
              <button
                onClick={handleMobileMenuToggle}
                className="text-white focus:outline-none"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <nav>
              <ul className="space-y-4">
                {menuItems.map((item) => (
                  <MobileDropdownLink key={item.id} item={item} language={language} />
                ))}
              </ul>
            </nav>
          </div>
          <div className="px-4 py-6 border-t border-green-600">
            <div className="flex flex-wrap justify-center gap-2 mb-4">
              {staticHeaderItems.map((item) => (
                <a
                  key={item.id}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex items-center justify-center gap-2 rounded-md w-full px-4 py-2 text-xs text-white font-bold bg-primary transition duration-300 ${language == 'ar' ? 'flex-row-reverse' : 'flex-row'}`}
                >
                  <span>{t(item.name, item.name)}</span>
                  <ExternalLink className={`h-4 w-4`} />
                </a>
              ))}
            </div>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => handleLanguageChange()}
                className={`px-4 py-2 rounded-md text-sm font-bold ${language === 'fr' ? 'bg-green-600 text-white' : 'text-white border border-white'}`}
              >
                {t('fr', 'FR')}
              </button>
              <button
                onClick={() => handleLanguageChange()}
                className={`px-4 py-2 rounded-md text-sm font-bold ${language === 'ar' ? 'bg-green-600 text-white' : 'text-white border border-white'}`}
              >
                {t('ar', 'AR')}
              </button>
            </div>
          </div>
        </MobileMenuContainer>
      )}
    </header>
  );
};

export default Navigation;